import $ from 'jquery';
import 'what-input';


  
// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.$ = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

$(document).foundation();

jQuery(document).ready(function () {
    jQuery("#ngallery").nanogallery2({
        thumbnailWidth:   'auto',
        thumbnailHeight:  300,
        kind:             'nano_photos_provider2',
        dataProvider:     './assets/components/nano_photos_provider2.php',
        locationHash:     false,
        thumbnailBorderVertical: '0',
        thumbnailBorderHorizontal: '0',
        thumbnailHoverEffect2: 'imageScale150',
        thumbnailLabel: {
            display: false,
          },
          thumbnailAlignment: 'center',
    });
});


//sticky footer

window.onload = function() {
    stickyFooter();

    //you can either uncomment and allow the setInterval to auto correct the footer
    //or call stickyFooter() if you have major DOM changes
    //setInterval(checkForDOMChange, 1000);
};

//check for changes to the DOM
function checkForDOMChange() {
    stickyFooter();
}

//check for resize event if not IE 9 or greater
window.onresize = function() {
    stickyFooter();
}

//lets get the marginTop for the <footer>
function getCSS(element, property) {

    var elem = document.getElementsByTagName(element)[0];
    var css = null;

    if (elem.currentStyle) {
        css = elem.currentStyle[property];

    } else if (window.getComputedStyle) {
        css = document.defaultView.getComputedStyle(elem, null).
        getPropertyValue(property);
    }

    return css;

}

function stickyFooter() {

    if (document.getElementsByTagName("footer")[0].getAttribute("style") != null) {
        document.getElementsByTagName("footer")[0].removeAttribute("style");
    }

    if (window.innerHeight != document.body.offsetHeight) {
        var offset = window.innerHeight - document.body.offsetHeight;
        var current = getCSS("footer", "margin-top");

        if (isNaN(current) == true) {
            document.getElementsByTagName("footer")[0].setAttribute("style","margin-top:0px;");
            current = 0;
        } else {
            current = parseInt(current);
        }

        if (current+offset > parseInt(getCSS("footer", "margin-top"))) {
            document.getElementsByTagName("footer")[0].setAttribute("style","margin-top:"+(current+offset)+"px;");
        }
    }
}

// end sticky footer



